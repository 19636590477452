<div class="h-100">
<div class="call-to-action">
    <button mat-icon-button (click)="back()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <div>Select time slot</div>
</div>

<div class="inner-container" *ngIf="treatment">
    <div class="d-flex flex-column justify-content-between h-100 bg-red">
        <ng-container *ngIf="selectedNearestStaff && selectedNearestTime && selectedNearestTime.length">
            Your selected appointment</ng-container>

        <ng-container *ngIf="!(selectedNearestStaff && selectedNearestTime && selectedNearestTime.length)">
            <p class="mt-3">{{ treatment.name }}
                with
                <span *ngIf="selectedStaff && selectedStaff.firstname; else anyStaff">{{ selectedStaff.firstname }} {{ selectedStaff.lastname }}</span>

                <ng-template #anyStaff>
                    any available practioner
                </ng-template>
            </p>
        </ng-container>


        <div class="component-container">
            <div class="calendar-container ">
                <div class="d-flex justify-content-center">
                        <button mat-icon-button (click)="previousDay()">
                            <mat-icon>chevron_left</mat-icon>
                        </button>
                        <mat-form-field class="date-pick">
                            <input  matInput 
                                [matDatepicker]="picker" 
                                [formControl]="datePicker"
                                [min]="today" 
                                (dateInput)="selectDate($event)" 
                                (dateChange)="selectDate($event)"> 
                            <mat-hint>DD/MM/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker>
                            </mat-datepicker>
                        </mat-form-field>
                        <button mat-icon-button (click)="nextDay()">
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                </div>
            </div>


            <div class="time-select-container">
                <div class="avail-times d-flex justify-content-left flex-wrap">
                    <div class="showdate text-center">{{ nearestStart | date : "fullDate" }}</div>
                    <button class="nearestStaff m-3 p-2" 
                        *ngFor="let time of nearestTimes" (click)="nearestTimeSelected(time)"
                        mat-button>
                        {{time.startTime | date : 'HH:mm'}}
                    </button>
                    <div class="text-center no-avail" *ngIf="nearestTimes && nearestTimes.length==0">
                        No availability on this date please try another.
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</div>