import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store/reducers';

@Component({
    selector: 'app-visited-before',
    templateUrl: './visited-before.component.html',
    styleUrls: ['./visited-before.component.scss', '../../pages/welcome/welcome.component.scss']
})
export class VisitedBeforeComponent implements OnInit {

    public loggedIn: boolean

    constructor(private router: Router,
        private store: Store<fromRoot.State>) {
        this.store.select(fromRoot.isPatientLoggedIn).subscribe((loggedIn: boolean) => {
            if (loggedIn) router.navigate(['rebook'])
        });
    }

    ngOnInit(): void {
    }

    redirect(url: string[]) {
        this.router.navigate(url)
    }
}
