import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DataService } from './data.service';
import { NotificationService } from './notification.service';
import { Store } from "@ngrx/store";
import * as fromRoot from '../store/reducers';
import * as fromAppointment from "../store/actions/appointment";
import * as fromBooking from "../store/actions/booking";

import { AvailabilityService } from './availability.service';
import { LoadingService } from './loading.service';
import { ConfigService } from './config.service';
import { tap } from 'rxjs/operators';
import { Treatment } from '../models/treatment';
import { AppointmentRequest } from '../models/booking';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  public diarySettings$: Subscription;
  public diarySettings: any;
  public  confirmationRequired: boolean;

  constructor(private http: DataService,
    private notification: NotificationService,
    private configService: ConfigService,
    private router: Router,
    private store: Store<fromRoot.State>,
    private availabilityService: AvailabilityService,
    private loadingService: LoadingService) {
    this.diarySettings$ = this.configService.getStoredDiarySettings().subscribe((settings: any) => this.diarySettings = settings);
    this.configService.approvalRequired().subscribe(res=>{this.confirmationRequired = res})
  }

  public formatDate(rawDate: Date): any {
    const date = rawDate.getFullYear() + "-" + this.includeZeros(rawDate.getMonth() + 1) + "-" + this.includeZeros(rawDate.getDate());
    const time = this.includeZeros(rawDate.getHours()) + ":" + this.includeZeros(rawDate.getMinutes());

    return { date, time };
  }

  private includeZeros(value: number): any {
    return (value.toString().length === 1) ? "0" + value : value;
  }
/*
  public email(patientIdx: number, staffName: string, start: Date, appointmentIdx: number): Observable<any> {
    return this.http.post("/public/email", { patientIdx, staffName, startTime: this.formatDate(start), appointmentIdx });
  }
*/

  public emailAppointment(): void{
    this.store.select(fromRoot.getRequestedAppointment).pipe(take(1)).subscribe(res=>{
      console.log("[email]", res);
      if(!res || !res.idx){
        throw new Error("No appointment to email");
        return EMPTY;
      }
      return this.http.post("/public/email", { appointmentIdx: res?.idx }).subscribe(res => {});
    })
  }

  public clashcheck(payload: any): Observable<any> {
    return this.http.post("/public/appointments/clashcheck", payload);
  }

  public save(booking: AppointmentRequest, patient: any, staff: any): Observable<{idx: number, ok: boolean, requirePrePayment: number, paymentRequestId: string}> {

    const adjustedTime = new Date(booking.startTime);
    adjustedTime.setTime(adjustedTime.getTime() - adjustedTime.getTimezoneOffset() * 60 * 1000);

    const payload = {
      startTime: adjustedTime,
      duration: booking.duration,
      patientIdx: patient.idx,
      staffIdx: booking.staffIdx,
      treatmentIdx: booking.treatmentIdx,
      notes: "Online booking",
      status: this.confirmationRequired ? "Pending-approval" : "Booking",
      bookedBy: null,
      groupSize: 1
    }

    this.loadingService.start();

    return this.send(payload).pipe(tap((newAppointment: any) => {
     // this.email(patient.idx, staff.firstname + " " + staff.lastname, new Date(booking.startTime), newAppointment[0].idx).subscribe(() => { });

       
        //  this.setAppointment(newAppointment.idx, booking);

          // this.store.dispatch(fromBooking.ClearBookingTimes());
      this.store.dispatch(fromBooking.SetAppointmentIdx({payload: newAppointment.idx}));

    }, (error) => setTimeout(() => this.loadingService.stop(), 2000)))
  }

  public send(payload: any): Observable<string[]> {
    console.log(payload, 'email payload')
    const datePayload = {...payload, startTime: DateTime.fromJSDate(payload.startTime).setZone("UTC").toFormat("yyyy-MM-dd HH:mm:ss")} //dates are always actual not time zoned
    return this.http.post<string[]>('/public/diary/appointment', datePayload);
  }

  public setAppointment(idx: number, booking: any): void {
    const payload = {
      idx,
      StartTime: booking.startTime,
      Duration: booking.duration,
      Status: "Pending-approval",
      BookingMadeAt: new Date()
    }

    this.store.dispatch(fromAppointment.SetAppointment({ payload }));
  }

  public sameDay(d1: Date, d2: Date): boolean {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  /**
   * 
   * @param treatment 
   * @param availabilities 
   * @param appointments 
   * @param payload 
   * @returns array of availability
   */
  public getNearestAppointment(treatment: Treatment, availabilitiesOg: any, appointments: any, payload: any): any {
    console.log("[avail]", availabilitiesOg);
    console.log("[apps]", appointments);
    console.log("[treatment]", treatment);
    console.log("[payload]", payload);
    if (!treatment){
      return;
    }
    // filter for staff not in list
    const staff = payload.selectVals;

    let availabilities:any[] = [];
    availabilitiesOg.forEach(element => {
        availabilities.push(Object.assign({}, element));
    });

    availabilities = availabilities.filter(a => {
      const found = staff.find(s => (s == a.staffIdx));
      return found;
    });

    // filter on start/stop range sent
    const startMins = this.availabilityService.dateToAvailMins(payload.startTime);
    const endMins = this.availabilityService.dateToAvailMins(payload.endTime);

    console.log(`[avail] On day searching between ${startMins} to ${endMins}`);

    for (let avail of availabilities) {
      avail.available = avail.available.filter(a => (a >= startMins && a <= endMins));
    }
    console.log("[avail] filtered with blanks", JSON.stringify(availabilities));
    // remove staff with 0 availbility
    availabilities = availabilities.filter(a => { return (a.available.length > 0) });


    console.log("[avail] filtered no blanks", JSON.stringify(availabilities));



    // ok now go for finding appointments
    for (let avail of availabilities) {
      const appsFiltered = appointments.filter(a => (a.staffIdx == avail.staffIdx));
      console.log("[avail] APPSs for",avail.staffIdx,  JSON.stringify(appsFiltered));
      for (let app of appsFiltered) {
        const appStartMins = this.availabilityService.dateToAvailMins(new Date(app.startTime));
        const appEndMins = appStartMins + app.duration;

        console.log(`Search and delete from ${appStartMins} to ${appEndMins}`);

        avail.available = avail.available.filter(a => (!(a >= appStartMins && a < appEndMins)));
        
        console.log("[avail] filtered apps removed", JSON.stringify(availabilities));
      }
    }
    console.log("[avail] filtered apps removed", JSON.stringify(availabilities));
    // remove staff with 0 availbility
    availabilities = availabilities.filter(a => (a.available.length > 0));



    //now find blocks of availability
    const duration = treatment.defaultDuration;
    const increment = this.diarySettings.increment
    const slotsAvailable:any[] = []; //this is what we will send back

    
    

    for (let avail of availabilities) {
      let lastSlot:number = 0;
      let lastTime: number = 0;

      for (let time of avail.available) {
        if (+time - +lastTime > increment) {
          console.log(`JUMP -> ${time} - ${lastSlot} = ${time - lastSlot}`);
          lastSlot = time; //handle jumps > increment by ignoring them
        }

        if (lastSlot > 0 && (+time + increment) - +lastSlot >= duration) {  //a valid slot has been found
          slotsAvailable.push({ staffIdx: avail.staffIdx, startTime: lastSlot });
          lastSlot = time+increment; //move base to the end of the slot and continue
        }
        lastTime = time;

      }
    }



//process slots
    return slotsAvailable.sort((a, b)=>(a.startTime-b.startTime)).map(slot => {
      return {
        staffIdx: slot.staffIdx,
        mins: slot.startTime,
        startTime: this.availabilityService.availMinsToDate(payload.startTime, slot.startTime)
      }
    })

    

  }



  sqlDateConvert(date: string): Date {
    // Split timestamp into [ Y, M, D, h, m, s ]
    const t = date.split(/[- :]/);

    // Apply each element to the Date function
    const d = new Date(Date.UTC(+t[0], +t[1] - 1, +t[2], +t[3], +t[4], +t[5]));
    return d;
    // -> Wed Jun 09 2010 14:12:01 GMT+0100 (BST)
  }
}
