import { Component, HostListener, OnInit } from '@angular/core';
import { BookingService } from 'src/app/services/booking.service';
import { Router } from '@angular/router';
import * as fromRoot from '../../store/reducers';
import * as fromBooking from "../../store/actions/booking";
import { ConfigService } from 'src/app/services/config.service';
import { LoadingService } from 'src/app/services/loading.service';
import { trigger, transition, animate, style } from '@angular/animations'
import { Subscription } from 'rxjs';
import { initialState, State } from 'src/app/models/patient';
import { Store } from '@ngrx/store';
import { Treatment } from 'src/app/models/treatment';

@Component({
  selector: "app-treatment",
  templateUrl: "./treatment.component.html",
  styleUrls: ["./treatment.component.scss"]
  
})
export class TreatmentComponent implements OnInit {
  public treatments: any = [];
  public mobile: boolean;
  public availabilities: any;
  public appointments: any;
  public selectedClinic$: Subscription;
  public selectedClinic: any;
  public selectedStaff: any = null;
  public selectedTreatment: any = null;
  public loggedIn: boolean;
  public patient: any;
  


  constructor(
    public bookingService: BookingService,
    private router: Router,
    private store: Store<fromRoot.State>,
    private configService: ConfigService,
    private loadingService: LoadingService
  ) {
    this.store
      .select(fromRoot.getPatientDetails)
      .subscribe((patient: State) => (this.patient = patient));
  }

  ngOnInit(): void {
    this.store.select(fromRoot.getTreatmentAndStaff).subscribe(info => {
      console.log("[info]", info);
      this.loadBookingData(info.staff, info.treatmentTypeIdx);
    })
    
  }

  ngOnDestroy(): void {}


  public loadBookingData(staff: number[], treatmentTypeIdx:number): void {
    this.loadingService.start();
    console.log("[filter]", treatmentTypeIdx);

    this.bookingService.getFilteredBookingData(treatmentTypeIdx).subscribe((treatments) => {
      console.log(treatmentTypeIdx, treatments);
      this.treatments = treatments[0];

      this.loadingService.stop();
    });
  }

  selectTreatment(treatment: Treatment){
    console.log("[treatment] set ", treatment)
    this.store.dispatch(fromBooking.SetTreatmentDetails({payload: treatment}));
    this.router.navigate(["appointment"]);
  }

  back(){ //staff/:treatmentCat/:treatmentIdx
    //console.log(this.treatments);
    this.router.navigate(["staff", this.treatments.idx, "0"]);
  }
 
}
