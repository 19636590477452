import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SiteConfig, SiteLinks } from 'src/app/models/config';
import * as fromRoot from 'src/app/store/reducers';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input("title") title: string;

  public links: SiteLinks | undefined;

  constructor(private store: Store<fromRoot.State>) { 
    this.store.select(fromRoot.getSiteConfig).pipe(takeUntilDestroyed()).subscribe(res=>{
      this.links = res;
    })

  }

  ngOnInit(): void {
    
  }

}
