import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from "@ngrx/store";
import { initialState, State } from 'src/app/models/patient';
import * as fromRoot from '../../store/reducers';
import { PatientService } from 'src/app/services/patient.service';
import { ConfigService } from 'src/app/services/config.service';
import { LoadingService } from 'src/app/services/loading.service';


import * as moment from 'moment';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent implements OnInit {
  @ViewChild('registrationForm') registrationForm;
  public FirstName = new UntypedFormControl('', [Validators.required]);
  public LastName = new UntypedFormControl('', [Validators.required]);
  public dob = new UntypedFormControl('', [Validators.required, this.dateValidator]);
  public EMail = new UntypedFormControl('', [Validators.required, Validators.email]);
  public MobilePhone = new UntypedFormControl('', [Validators.required]);
  public sms = new UntypedFormControl(true);
  public mailshot = new UntypedFormControl(true);
  public mobile: boolean;
  public ConsentMarketing = new FormControl<boolean>(false);
  public loggedIn: boolean = false;
  public patient: State = initialState;
  public clinic: any;
  public emailClinic: string;
  public dateInputTouched: boolean = false;
  public duplicateWarning = false;

  constructor(private store: Store<fromRoot.State>,
    private patientService: PatientService,
    private config: ConfigService,
    private router: Router,
    private loadingService: LoadingService) {
    this.store.select(fromRoot.isPatientLoggedIn).subscribe((loggedIn: boolean) => this.loggedIn = loggedIn);
    this.store.select(fromRoot.getPatientDetails).subscribe((patient: State) => this.patient = patient);

    this.config.getStoredClinicDetails().subscribe((clinic: any) => this.clinic = clinic);
  }

  ngOnInit(): void {
    this.setMobile(window.innerWidth);
    this.loadingService.stop();
  }

  private setMobile(windowWidth: number): void {
   // this.mobile = windowWidth < 992;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setMobile(event.target.innerWidth);
  }

  public getErrorMessage(field: string): string {
    if (this[field].hasError('required')) {
      switch (field) {
        case "MobilePhone": return "You must provide your mobile phone.";
        case "FirstName": return "You must provide your first name.";
        case "LastName": return "You must provide your last name";
        case "EMail": return "You must provide your email address";
      }
    }
    return this[field].hasError('email') ? 'You must provide a valid email address.' : '';
  }

  public formValid(): boolean {
    return this.FirstName.valid && this.LastName.valid && this.EMail.valid && this.MobilePhone.valid;
  }

  

  public send(): void {

    this.loadingService.start();
    if (!this.formValid()) return;

    const formFields: any = {
      FirstName: this.FirstName.value,
      LastName: this.LastName.value,
      DOB: this.formatDOB(this.dob.value),
      EMail: this.EMail.value,
      MobilePhone: this.MobilePhone.value,
      DefaultClinicIdx: this.clinic.idx,
      sms: this.sms.value,
      mailshot: this.mailshot.value,
      ConsentMarketing: this.ConsentMarketing.value
    };

    this.patientService.save(formFields).subscribe((savedPatient: any) => {
      console.log("[patient]",savedPatient);
      if(savedPatient.duplicate){
        this.duplicateWarning  = true;
        this.loadingService.stop();
        return;
      }
      formFields.NewPatient = true

      const patientIdx = savedPatient;
      this.patientService.setPatient(formFields, patientIdx, true);
      this.loadingService.stop();
      this.router.navigate(["/staff"])
    })
  }

  

  public mousedOver(event: any): void {
    if (!this.dob.value) this.dateInputTouched = true;
    if (this.dob.value) this.dateInputTouched = false;
  }

 
  

  public dateValidator(control: FormControl): { [s: string]: boolean } | null {

    if (!control.value) {
      return null;
    }

    const splitDate = control.value.split("/");

    if (splitDate.length != 3) {
      console.log("invalidate date: ", control.value);
      return { 'invalidDate': true }
    }

    if (splitDate[0] > 0 && splitDate[0] < 32
      && splitDate[1] > 0 && splitDate[1] < 13
      && splitDate[2] > 1900 && splitDate[1] < 2023) {
      console.log("valid date: ", control.value);
        return null;
      //return { 'invalidDate': false };
    }
    console.log("invalidate date: ", control.value);
    return { 'invalidDate': true }
  }

  private formatDOB(dob: string): string {
    const splitDate = dob.split("/");
    return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
  }

  home(){
    this.router.navigate(['/']);
  }
}