import { Component,  OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Store } from "@ngrx/store";
import { initialState, State } from 'src/app/models/patient';

import * as fromRoot from '../../store/reducers';
import * as PatientActions from '../../store/actions/patient';

import { Location } from '@angular/common';
import { AppointmentRequest } from 'src/app/models/booking';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppointmentService } from 'src/app/services/appointment.service';

@Component({
  selector: 'app-completed-booking',
  templateUrl: './completed-booking.component.html',
  styleUrls: ['./completed-booking.component.scss']
})

export class CompletedBookingComponent implements OnInit {
  public mobile: boolean;
  public loggedIn: boolean = false;
  public patient: State = initialState;
  public staff: any;
  public activeAppointment: any;
  public clinic: any;
  public emailClinic: string;
  public appointmentSaved = false;
  public isPaid: string = 'false';
  public confirmationRequired: boolean;
  public requestedAppointment: AppointmentRequest;

  constructor(private store: Store<fromRoot.State>,
    private router: Router,
    private _location: Location,
    private appointmentService: AppointmentService,
    private route: ActivatedRoute
) {    
    this.store.select(fromRoot.getPatientDetails).pipe(takeUntilDestroyed()).subscribe((patient: State) => this.patient = patient);
    this.confirmationRequired = this.appointmentService.confirmationRequired;
  }


  ngOnInit(): void {
  }

  buttonPress(action: string) {
    switch (action) {
      case "book-another":
        this.router.navigate(["/"])
        break;
      case "logout":
        this.store.dispatch(PatientActions.ClearStore());
        break;
      case "details":
        this.router.navigate(["/details"], { queryParams: { newPatient: true } });
        break;
      case "go-back":
        this._location.back();
        break;
    }
  }

}